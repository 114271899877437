import { Side, Unit } from "../units/unit";
import { observable, computed, action } from "mobx";
import { handleUnitInFight } from "./combat";

export class Fight {
    @observable
    units: Unit[] = [];
    
    /**
     * Units engaged in this fight that are still alive.
     */
    @computed
    get aliveUnits() { 
        return this.units.filter(x => !x.isDead);
    }

    /**
     * Whether the fight is over -> one side is all dead.
     */
    @computed
    get isOver() { 
        return !(this.leftSide.some(x => !x.isDead) && this.rightSide.some(x => !x.isDead));
    }

    /**
     * All the units on the left side.
     */
    @computed
    get leftSide() {
        return this.units.filter(x => x.side === Side.Left);
    }

    /**
     * All the units on the right side.
     */
    @computed
    get rightSide() {
        return this.units.filter(x => x.side === Side.Right);
    }

    /**
     * Add a unit to this fight.
     * @param unit the unit to add
     * @param side the side on which to add it
     */
    @action
    add(unit: Unit, side: Side) {
        unit.side = side;
        this.units.push(unit);
    }

    /**
     * Handle the fight. Blocks until the fight is over.
     */
    async do():Promise<Side> { 
        let promises = [];
        for (const attacker of this.aliveUnits) {
            promises.push(handleUnitInFight(attacker, this));
        }
        await Promise.all(promises);

        return (this.leftSide.some(x => !x.isDead)) ? Side.Left : Side.Right;
    }
}