import { Unit } from "../units/unit";
import { Settings } from "../../tools/settings";
import { CombatLogs } from "../../tools/combat-logs";
import { ElementalType } from "../stats";
import { Tools } from "../../tools/tools";
import { Fight } from "./fight";
import { texts } from "./texts";

const enum HitResult {
    MISS,
    DODGE,
    PARRY,
    BLOCK,
    HIT,
    NB
}

/**
 * Computes the outcome of an attack
 * @param attacker the attacking unit
 * @param target the defending unit
 */
function computeHitResult(attacker:Unit, target:Unit) {
    //mise à jour des seuils hors hit
    let ranges:number[] = [];
    ranges[HitResult.MISS] = Settings.combat.baseMissChance;
    ranges[HitResult.DODGE] = target.dodgeProbability;
    ranges[HitResult.PARRY] = target.parryProbability;
    ranges[HitResult.BLOCK] = target.blockProbability;

    // calcul du hit
    let hit = attacker.hitProbability;

    // on mange les miss d'abord
    hit -= ranges[HitResult.MISS];
    if (hit < 0) ranges[HitResult.MISS] = -hit;
    else ranges[HitResult.MISS] = 0;

    //on réduit les autres chances de rater
    let min, nbPositiveRanges;
    do {
        min = 0;
        nbPositiveRanges = 0
        for (let i=1; i < HitResult.HIT; i++) {
            if (ranges[i] > 0) {
                nbPositiveRanges++;
                if (!min || ranges[i] < min) min = ranges[i];
            }
        }
        if (nbPositiveRanges > 0) {
            min = Math.min(min, hit/nbPositiveRanges);
            for (let i=1; i < HitResult.HIT; i++) {
                if (ranges[i] > 0) ranges[i] -= min;
            }
            hit -= nbPositiveRanges * min;
        }
    }
    while (hit > 0 && nbPositiveRanges > 0);
    
    let rand = Math.random();

    for (let i=0; i < ranges.length; i++) {
        if (rand < ranges[i]) return i;
        else rand -= ranges[i];
    } 
    return HitResult.HIT;
}

/**
 * Base attack
 * @param attacker the attacking unit
 * @param target the defending unit
 */
function attack(attacker:Unit, target:Unit) {
    /* TODO : move texts to external file */
    if (attacker.remainingResource < Settings.combat.baseResourceCost) {
        CombatLogs.add(texts.formatString(texts.NOT_ENOUGH_ENERGY, attacker.name).toString());
    } else {
        let res:HitResult = computeHitResult(attacker, target);
        if (res == HitResult.MISS){
            CombatLogs.add(texts.formatString(texts.MISS, attacker.name, target.name).toString());          
        } else if (res == HitResult.DODGE){
            CombatLogs.add(texts.formatString(texts.DODGE, attacker.name, target.name).toString());           
        } else if (res == HitResult.PARRY){
            CombatLogs.add(texts.formatString(texts.PARRY, attacker.name, target.name).toString());           
        } else {
            /* the attack lands, compute resulting damage */     
            let crit:boolean = Math.random() < attacker.critProbability;
            let damage:number;
            let totalDamage:number = 0;
            let defended:number;
            let totalDefended:number = 0;

            for (let i:number=0; i < ElementalType.NB; i++) {
                damage = Math.round(attacker.stats.damage[i] * (1 + Tools.computeVariance(Settings.combat.damageVariance)));
                if( crit ) damage *= attacker.stats.critDamage;
                defended = Math.round(damage * target.damageReduction[i]);
                totalDamage += damage;
                totalDefended += defended;
            }        
            
            if (res == HitResult.BLOCK) totalDefended += target.stats.blockAmount;
            if (totalDefended > totalDamage) totalDefended = totalDamage;
            
            target.loseLife(totalDamage - totalDefended);

            CombatLogs.add(texts.formatString   (texts.HIT, 
                                                    attacker.name, target.name, 
                                                    (totalDamage - totalDefended).toString(), 
                                                    (totalDefended - target.stats.blockAmount).toString(),
                                                    target.stats.blockAmount.toString(),
                                                    (crit)?texts.CRIT:""
                                                ).toString());         
        }

        attacker.loseResource(Settings.combat.baseResourceCost);
    }
}

/**
 * Handles a unit in a fight. Does not return until the attacker is dead, or the fight is over.
 * @param attacker the unit that fights
 * @param fight the fight in which it fights
 */
export async function handleUnitInFight(attacker:Unit, fight:Fight) { 
    await Tools.wait(attacker.delayBetweenActions); 
    while (!attacker.isDead && !fight.isOver) {   
        /* TODO : check buff & debuff status, apply */

        /* TODO : handle other actions, heal, buff, etc */

        /* TODO : handle AE skills */

        /* TODO : handle target choice */
        const target = fight.aliveUnits.find(x => x.side !== attacker.side);
        if (target) {
            /* TODO : handle special attack skills */

            /* base attack */
            attack(attacker, target); 
        }
    
        /* restore resource and life */
        attacker.gainResource(attacker.stats.resourceRegen * Settings.combat.resourceRegenInCombat);
        attacker.gainLife(attacker.stats.lifeRegen * Settings.combat.lifeRegenInCombat);
        
        await Tools.wait(attacker.delayBetweenActions);
    }
}