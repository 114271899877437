export  class Tools {
    static wait(ms: number):Promise<unknown> {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        })
    }
    
    static computeVariance(variance:number):number {
        return Math.floor(Math.random() * (2 * variance + 1)) - variance;
    }    
}