import { observable, action } from "mobx";
import { Settings } from "./settings";

export class CombatLogs {
    static instance?:CombatLogs = undefined;

    @observable
    logs:string[] = [];

    @action
    static add(log: string) {
        if (!this.instance) this.instance = new CombatLogs();
        if (this.instance.logs.length >= Settings.combatLogs.maxLength) this.instance.logs.pop();
        this.instance.logs.unshift(log);
    }

    @action 
    static get() {
        if (!this.instance) this.instance = new CombatLogs();
        return this.instance;
    }
}