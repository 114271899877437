export interface DiminishingReturnParams {
    /* resulting value when input is 0 */
    base:number;
    /* when input tends to infinity, resulting value tends to base + range */
    range:number;
    /* input value at which the resulting value is base + range/2 */
    midrangeValue:number;
}

export class DiminishingReturn {
    // TODO : Insérer le niveau dans les calculs pour scaler.
    static compute(value:number, params:DiminishingReturnParams) {
        return params.base + value * params.range / (params.midrangeValue + value);
    }
}