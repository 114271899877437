import * as React from "react";
import { observer, inject } from "mobx-react";
import { CombatView } from "./combat-view";
import { UnitView } from "./unit-view";
import { CombatLogView } from "./combat-log-view";
import { CombatLogs } from "../tools/combat-logs";
import { Game } from "../game/game";

export interface MainProps {
    game:Game;
}

@inject("game")
@observer
export class Main extends React.Component<MainProps> {
    render() {
        let game = this.props.game;
        return <div>
            {(game.fight)
                ? <CombatView fight={game.fight} />
                /* TODO : switch to a "RestView" */
                : <UnitView unit={game.player} />
            }
            <CombatLogView combatLog={CombatLogs.get()} />
        </div>
    }
}