import { observer } from "mobx-react";
import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Fight } from "../game/engine/fight";
import { UnitView } from "./unit-view";

export interface CombatViewProps {
    fight: Fight;
}

@observer
export class CombatView extends React.Component<CombatViewProps>{
    render() {
        return <Grid>
            <Grid.Column width="8">{ this.props.fight.leftSide.map(x => <UnitView key={x.id} unit={x} />) }</Grid.Column>
            <Grid.Column width="8">{ this.props.fight.rightSide.map(x => <UnitView key={x.id} unit={x} />) }</Grid.Column>
        </Grid>;
    }
}