export enum ElementalType {
    PHYSICAL,
    FIRE,
    WATER,
    EARTH,
    AIR,
    LIGHT,
    DARK,
    NB
}

// Stats sur un personnage (mob ou joueur) ou un objet.
export interface Stats {    
    // declaration du type de contenu 
    [index:string]:number|number[];
    
    // Attaque : dégâts de base (pour le calcul du DPS) par élément
    damage:number[];
    // Défense : % de réduction de dégâts reçus par élément
    defense:number[];
    // Vie : augmente les hp
    maxLife:number;
    // Régénération vie : vitesse de régénération de la vie hors combat (en combat, ratio à déterminer)
    lifeRegen:number;
    // Max ressource : quantité de ressource maximale disponible. La ressource est dépensée dans une quantité de base par cycle, et est utilisée en plus pour les compétences.
    maxResource:number;
    // Régénération ressource : vitesse de régénération de la ressource hors combat (en combat, ratio à déterminer)
    resourceRegen:number;
    // Toucher : bonus aux chances de toucher en attaquant
    hit:number;
    // Esquive : malus aux chances de toucher
    dodge:number;
    // Parade : malus aux chances de toucher
    parry:number;
    // Vitesse de déplacement : réduit le temps de déplacement entre les combats
    move:number;
    // Vitesse d’attaque : réduit le temps entre les attaques
    attackSpeed:number;
    // Réduction des CDs : réduit le temps de recharge des compétences
    cooldownReduction:number;
    // % critique : chances de réaliser un coup critique avec une attaque
    critChance:number;
    // Dégât des critiques : augmentation des dégâts d’un crit
    critDamage:number;
    // Bloc : chance de bloquer une attaque
    blockChance:number;
    // Montant de blocage : dégâts absorbés par le blocage
    blockAmount:number;
    // Tactique : augmente la probabilité que le personnage fasse les bons choix de cible
    tactics:number;
    // Leadership : pondération de la tactique dans un groupe. Base à xx (à déterminer, mais pas 0).
    leadership:number;
    // Port : nombre d’objets transportables dans l’inventaire.
    burden:number;
}

export const combineStats = (destination:Stats, toAdd:Stats[]) => {
    Object.keys(destination).forEach( (key, index) => {
        for (let stats of toAdd) {
            if (destination[key] instanceof Array) {
                for (let i in (destination[key] as number[])) 
                    (destination[key] as number[])[i] += (stats[key] as number[])[i];
            } else {
                (destination[key] as number) += (stats[key] as number);
            }
        }  
    } );
}