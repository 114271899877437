import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Unit } from "../game/units/unit";
import { observer } from "mobx-react";

export interface UnitViewProps {
    unit: Unit;
}

@observer
export class UnitView extends React.Component<UnitViewProps> {
    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column width={5}>{this.props.unit.name}</Grid.Column>
                <Grid.Column width={3}>Level {this.props.unit.level}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>Life : {Math.round(this.props.unit.remainingLife)}/{this.props.unit.stats.maxLife}</Grid.Column>
                <Grid.Column width={4}>Energy : {Math.round(this.props.unit.remainingResource)}/{this.props.unit.stats.maxResource}</Grid.Column>
            </Grid.Row>
        </Grid>
    }
}