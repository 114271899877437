import { observable } from "mobx";
import { Fight } from "./engine/fight";
import { Side, Unit } from "./units/unit";
import { Player } from "./units/player";
import { World } from "./world/world";
import { CombatLogs } from "../tools/combat-logs";

export class Game {
    static PLAYER_SIDE:Side = Side.Left;

    /* the player unit */
    @observable player:Player;

    /* the world the player wanders, a collection of zones */
    @observable world:World;

    /* the current fight, if any */
    @observable fight:Fight| null = null;
    
    /**
     * Called when a fight is over.
     * @param winningSide side of the current fight that won it
     */
    onFightEnd(winningSide:Side) {
        if (winningSide === Game.PLAYER_SIDE) {
            CombatLogs.add("Victoire !");
            /* TODO : get xp from fight */
            if (this.player.gainExperience(10)) {
                // level up
                this.world.enterZone(this.world.currentZone.id + 1);
            }
        } else {            
            CombatLogs.add("Défaite...");
        }
    }

    /**
     * Starts and plays a new fight between the two given teams until it's over
     * @param leftTeam array of units to put on the left side
     * @param rightTeam array of units to put on the right side
     */
    async handleFight(leftTeam: Unit[], rightTeam: Unit[]) {
        this.fight = new Fight();
        
        for (const unit of leftTeam) this.fight.add(unit, Side.Left);
        for (const unit of rightTeam) this.fight.add(unit, Side.Right);
        
        this.onFightEnd(await this.fight.do());
    }

    /**
     * Main game loop.
     */
    async play() {
        /* TODO : place player in world */

        for (; ;){
            /* rest until ready */
            await this.player.rest();

            if (this.world.currentZone.hasMobs()) {
                /* fight enemies */       

                /* TODO : handle grouping (companions, multiplayer ?) */
                await this.handleFight([this.player], this.world.currentZone.generateMobs());
                this.fight = null;
            }
        }
    }
    
    constructor() {
        this.player = new Player();
        this.world = new World();

        this.play();
    }

}