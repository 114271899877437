import { Unit } from "./unit";
import { computed } from "mobx";
import { Stats } from "../stats";

export class Mob extends Unit {
    mobLevel = 0;

    @computed get level():number { 
        return this.mobLevel; 
    }
    
    constructor(name:string, level:number, stats:Stats) {
        super(name, stats);
        this.mobLevel = level;
    }
}