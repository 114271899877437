import { Stats } from "../game/stats";

export class Settings {

    static game = {
        // délai entre 2 actions pour une vitesse d'attaque de 0 en ms
        baseTick:1000
    };

    static combat = {
        // coût d'une attaque de base
        baseResourceCost: 1,
        // Variation de dégâts d'une attaque (en + ou en -)
        damageVariance: 0.1,        

        // Ratio entre la regen hors combat et en combat.
        resourceRegenInCombat: 0.1,
        lifeRegenInCombat: 0.1,

        // chance de rater avec un hit à 0
        baseMissChance: 0.2
    };

    static combatLogs = {
        // nombre maximal de logs de combat stockés
        maxLength:10
    }

        /* Diminishing returns params */
    static diminishingReturns = {
        damageReduction: {
            base : 0, 
            range : 0.5, 
            midrangeValue : 100
        },
        critChance: {
            base : 0, 
            range : 1, 
            midrangeValue : 100
        },
        dodge: {
            base : 0, 
            range : 0.5, 
            midrangeValue : 100
        },
        parry: {
            base : 0, 
            range : 0.5, 
            midrangeValue : 100
        },
        blockChance: {
            base : 0, 
            range : 0.5, 
            midrangeValue : 100
        },
        hit: {
            base : 0, 
            range : 2, 
            midrangeValue : 100
        },
        attackSpeed: {
            base : 1, 
            range : -0.9, 
            midrangeValue : 100
        }
    }

    static zones = {
        // malus de niveau pour les mobs par membre dans leur groupe
        levelMalusPerGroupSize: 1,
        // variance aléatoire du niveau d'un monstre
        levelVariance: 1
    };

    static player = {
        // statistiques avec lesquelles un nouveau personnage est créé
        newPlayerStats: {
            damage:[2,0,0,0,0,0,0],
            defense:[0,0,0,0,0,0,0],
            maxLife:20,
            lifeRegen:1,
            maxResource:10, 
            resourceRegen:1,
            hit:0,
            dodge:0,
            parry:0,
            move:0,
            attackSpeed:100,
            cooldownReduction:0,
            critChance:0,
            critDamage:50,
            blockChance:0,
            blockAmount:0,
            tactics:0,
            leadership:0,
            burden:0
        },
        // xp pour passer du niveau 1 au 2. Par la suite, pour passer niveau n, il faut que xp/baseXpPerLevel > (n-1)²
        baseXpPerLevel: 10,
        // statistiques ajoutées lors d'un gain de niveau. TODO : à revoir avec les classes
        addedStatsPerLevel: {
            damage:[2,0,0,0,0,0,0],
            defense:[1,0,0,0,0,0,0],
            maxLife:5,
            lifeRegen:0.1,
            maxResource:1, 
            resourceRegen:0.1,
            hit:1,
            dodge:1,
            parry:1,
            move:0,
            attackSpeed:5,
            cooldownReduction:0,
            critChance:1,
            critDamage:5,
            blockChance:1,
            blockAmount:5,
            tactics:0,
            leadership:0,
            burden:0
        }        
    }

    // stats de base
    static emptyStats:Stats = {
        damage:[0,0,0,0,0,0,0],
        defense:[0,0,0,0,0,0,0],
        maxLife:0,
        lifeRegen:0,
        maxResource:0, 
        resourceRegen:0,
        hit:0,
        dodge:0,
        parry:0,
        move:0,
        attackSpeed:0,
        cooldownReduction:0,
        critChance:0,
        critDamage:0,
        blockChance:0,
        blockAmount:0,
        tactics:0,
        leadership:0,
        burden:0
    };

    
}

