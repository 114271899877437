import { Zone } from "./zone";
import { observable } from "mobx";
import { CombatLogs } from "../../tools/combat-logs";

export class World {
    zones:Zone[] = [];

    @observable currentZone:Zone;

    enterZone(zoneId:number) {
        if (zoneId > 0 && zoneId < this.zones.length && Number.isInteger(zoneId)) {
            this.currentZone = this.zones[zoneId];
            CombatLogs.add("Entrée dans "+this.currentZone.name);
        }
    }

    constructor () {
        /* TODO : create from data, or random generator ? */
        for (let index = 0; index < 100; index++) {
            this.zones.push(new Zone(index, "zone "+index, index, Math.round(index/10), Math.round(index/10)));            
        }
        this.currentZone = this.zones[0];
    }
}