import LocalizedStrings from 'react-localization';

export const texts = new LocalizedStrings({
    fr: {
        NOT_ENOUGH_ENERGY : "{0} manque d'énergie.",
        MISS : "{0} a attaqué {1} mais a raté son coup !",
        DODGE : "{1} a esquivé l'attaque de {0}.",
        PARRY : "{1} a paré l'attaque de {0}.",
        HIT : "{0} a frappé {1} pour {2} dégâts ({3} absorbés, {4} bloqués). {5}",
        CRIT : "Coup critique !"
    }
});