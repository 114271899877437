import { Unit } from "./unit";
import { Settings } from "../../tools/settings";
import { computed, observable, action } from "mobx";
import { combineStats } from "../stats";
import { Tools } from "../../tools/tools";

export class Player extends Unit {
    /* number of xp points the player has */
    @observable experience = 0;
    
    @computed get level() {
        return 1 + Math.floor(Math.sqrt(this.experience / Settings.player.baseXpPerLevel));
    }

    @computed get stats() {
        /* TODO : add stuff and auras */
        return this.baseStats;
    }
    
    /**
     * Add xp to the player.
     * @param xp number of xp added.
     * @returns whether the player has levelled up
     */
    @action gainExperience(xp:number):boolean {
        let oldLevel = this.level;
        this.experience += xp;
        if (this.level > oldLevel) {
            this.onLevelUp();
            return true;
       }
       return false;
    }

    /**
     * Called when the player gains a level.
     */
    @action onLevelUp() {        
        // TODO : revoir le système de stats vs level ?
        combineStats(this.baseStats, [Settings.player.addedStatsPerLevel]);
    }

    /**
     * Rest the character until full recovery
     */
    @action async rest() {
        while (this.remainingLife < this.stats.maxLife || this.remainingResource < this.stats.maxResource) {
            this.gainLife(this.stats.lifeRegen);
            this.gainResource(this.stats.resourceRegen);
            await Tools.wait(this.delayBetweenActions);
        }
    }

    constructor() {
        super("Player", Settings.player.newPlayerStats);
    }
}