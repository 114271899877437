import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { HashRouter, Switch } from "react-router-dom";
import { Route } from "react-router";
import { Container } from "semantic-ui-react";
import { Main } from "./ui/main";
import { Game } from "./game/game";

const root = document.getElementById("root");

const game:Game = new Game();

ReactDOM.render(
    <Provider game={game}>
        <HashRouter>
            <Container>
                <Switch>
                    <Route exact path="/" component={Main}/>
                </Switch>
            </Container>
        </HashRouter>
    </Provider>    
    ,
    root
);