import { Settings } from "../../tools/settings";
import { Mob } from "../units/mob";
import { Tools } from "../../tools/tools";
import { CombatLogs } from "../../tools/combat-logs";

export class Zone {
    id:number;
    name:String;
    level:number;
    level_variance:number;
    max_mobs_per_group:number;

    /* TODO : mob naming ressources */

    constructor (id:number, name:String, level:number, level_variance:number, max_mobs_per_group:number) {
        this.id = id;
        this.name = name;
        this.level = level;
        this.level_variance = level_variance;
        this.max_mobs_per_group = max_mobs_per_group;
    }

    generateMobs():Mob[] {
        /* TODO : tweak probabilities depending on zone settings */
        let nbMobs = 1 + Math.floor(Math.random() * this.max_mobs_per_group);
        CombatLogs.add("Nouveau pack avec "+nbMobs+" mobs : ");

        let mobs:Mob[] = [];
        for (let i=0; i<nbMobs; i++) {
            /* generate new mob */
            let level = this.level 
                + Tools.computeVariance(this.level_variance)
                - Math.round((nbMobs - 1) * Settings.zones.levelMalusPerGroupSize)
                + Tools.computeVariance(Settings.zones.levelVariance);
            level = Math.max(1, level);
            
            /* TODO : choose archetype */
            mobs.push(this.generateMob(level /*,archetype*/));
            CombatLogs.add("Mob "+i+" : "+mobs[i].name+" ("+mobs[i].level+")");
        }

        return mobs;
    }
    
    generateMob(level:number):Mob {
        /* TODO : randomize name depending on zone theme and archetype */
        let name = "Mob";

        /* TODO : set stats depending on level and archetype */
        let stats = Settings.emptyStats;
        stats.attackSpeed = 100 + (level-1) * 1;
        stats.blockAmount = (level-1) * 5;
        stats.blockChance = (level-1) * 1;
        stats.critChance = (level-1) * 1;
        stats.critDamage = 50 + (level-1) * 5;
        stats.damage[0] = level * 1.5;
        stats.defense[0] = (level-1) * 2;
        stats.dodge = (level-1) * 5;
        stats.hit = (level-1) * 5;
        stats.lifeRegen = level * 2;
        stats.maxLife = level * 10;
        stats.maxResource = level * 5;
        stats.resourceRegen = level * 1;
       
        return new Mob(name, level, stats);
    }

    hasMobs():boolean {
        return true;
    }
}