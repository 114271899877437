import { observable, computed, action } from "mobx";
import { Settings } from "../../tools/settings";
import { Stats } from "../stats";
import { DiminishingReturn } from "../engine/diminishing-return";

export const enum Side {
    NotEngaged,
    Left,
    Right
}

let serial:number = 0;
export class Unit {
    id:number = serial++;

    /* display name of the unit */
    name: string = "";

    /* base stats (no stuff, no aura) for this unit */
    @observable baseStats:Stats = Settings.emptyStats;

    /* counters for life and energy */
    @observable remainingLife:number = 0;
    @observable remainingResource:number = 0;
    
    /* side of the unit in the current fight */
    @observable side = Side.NotEngaged;

    /**
     * Returns the usable stats of the unit.
     */
    @computed get stats():Stats {
        return this.baseStats;
    }

    /**
     * Returns whether this unit is dead.
     */
    @computed get isDead():boolean {
        return this.remainingLife <= 0;
    }

    /**
     * Returns the current level of the unit. Should be overrided.
     */
    @computed get level():number {
        return 0;
    }
    
    /**
     * Returns the delay between two actions for this unit.
     */
    @computed get delayBetweenActions():number {
        return Settings.game.baseTick * DiminishingReturn.compute(this.stats.attackSpeed, Settings.diminishingReturns.attackSpeed);
    }    

    /**
     * Returns the damage reduction (0 -> no reduction, 1 -> 100% reduction) for each damage type for this unit.
     */
    @computed get damageReduction():number[] {
        return this.stats.defense.map(def => DiminishingReturn.compute(def, Settings.diminishingReturns.damageReduction));
    }
  
    /**
     * Returns the chance of one of this unit's hits to be critical.
     */
    @computed get critProbability():number {
        return DiminishingReturn.compute(this.stats.critChance, Settings.diminishingReturns.critChance);
    }
    
    /**
     * Returns the base chance of this unit to dodge an incoming hit.
     */
    @computed get dodgeProbability():number {
        return DiminishingReturn.compute(this.stats.dodge, Settings.diminishingReturns.dodge);
    }
    
    /**
     * Returns the base chance of this unit to parry an incoming hit.
     */
    @computed get parryProbability():number {
        return DiminishingReturn.compute(this.stats.parry, Settings.diminishingReturns.parry);
    }
    
    /**
     * Returns the base chance of this unit to block an incoming hit.
     */
    @computed get blockProbability():number {
        return DiminishingReturn.compute(this.stats.blockChance, Settings.diminishingReturns.blockChance);
    }
    
    /**
     * Returns the base chance of this unit to land a hit.
     */
    @computed get hitProbability():number {
        return DiminishingReturn.compute(this.stats.hit, Settings.diminishingReturns.hit);
    }
    
    /**
     * Removes HP from this unit
     * @param hp number of hp to lose
     */
    @action loseLife(hp:number) {
        this.remainingLife -= hp;
        if (this.remainingLife < 0) this.remainingLife = 0;
    }

    /**
     * Add HP to this unit
     * @param hp number of hp to gain 
     */
    @action gainLife(hp:number) {
        this.remainingLife += hp;
        if (this.remainingLife > this.stats.maxLife) this.remainingLife = this.stats.maxLife;
    }

    /**
     * Removes MP from this unit
     * @param mp number of MP to lose
     */
    @action loseResource(mp:number) {
        this.remainingResource -= mp;
        if (this.remainingResource < 0) this.remainingResource = 0;
    }

    /**
     * Add MP to this unit
     * @param mp number of MP to gain
     */
    @action gainResource(mp:number) {
        this.remainingResource += mp;
        if (this.remainingResource > this.stats.maxResource) this.remainingResource = this.stats.maxResource;
    }

    constructor (name:string, stats:Stats) {
        this.name = name;
        this.baseStats = stats;
        this.remainingLife = stats.maxLife;
        this.remainingResource = stats.maxResource;
    }

}