import * as React from "react";
import { Grid } from "semantic-ui-react";
import { observer } from "mobx-react";
import { CombatLogs } from "../tools/combat-logs";

export interface CombatLogViewProps {
    combatLog: CombatLogs;
}

@observer
export class CombatLogView extends React.Component<CombatLogViewProps> {
    render() {
        return (<Grid>
            {this.props.combatLog.logs.map((log, index) => (<Grid.Row key={index}>{log}</Grid.Row>))}            
        </Grid>)
    }
}